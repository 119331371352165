import React from 'react';
import { graphql } from 'gatsby';
import styled from '@emotion/styled';
import Img from 'gatsby-image';
import Layout from '../components/shared/layout';
import SEO from '../components/SEO/SEO';
import { breakpoints, border, color } from '../utils/style';
import { post_normalizer } from '../utils/post';
import ButtonLink from '../components/shared/ButtonLink/index';
import Breadcrumbs from '../components/shared/Breadcrumbs';
import { getFile } from '../utils/system';
import TopTen from '../components/shared/TopTen';
import dictionary from '../utils/dictionary';

const WrapperDesktop = styled('div')`
  background-color: ${color.bunting};
  margin-top: 25px;
  padding: 40px 30px;
  display: flex;
  border-radius: ${border.borderRadius4};

  & h1 {
    height: 77px;
    padding: 0;
    margin: 0;

    font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
    font-size: 26px;
    font-weight: bold;
    color: ${color.lightningYellow};
  }

  @media (max-width: ${breakpoints.md}) {
    flex-direction: column;
    padding: 0 12px 0 12px;
    background-color: transparent;

    & h1 {
      height: max-content;
      margin-top: 30px;
      margin-bottom: 30px;
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 22px;
      font-weight: bold;
      color: ${color.lightningYellow};
    }
  }
`;

const ContentWrapper = styled('div')`
  width: 754px;
  background-color: ${color.bunting};
  border-radius: ${border.borderRadius4};

  & .content {
    margin-top: 30px;

    & p {
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 14px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: 1.57;
      letter-spacing: normal;
      color: ${color.white};
    }

    & h2 {
      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 19px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${color.lightningYellow};
    }
  }

  & .share {
    display: flex;
    margin-top: 25px;
    user-select: none;

    & > span {
      margin-right: 10px;

      font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
      font-size: 19px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: ${color.lightningYellow};
    }

    a {
      margin: 0 5px;
    }
  }

  @media (max-width: ${breakpoints.md}) {
    width: calc(100% + 32px);
    margin-bottom: 60px;
    margin-left: -16px;
    padding: 0 16px;

    & .content {
      & p {
        font-family: Kanit, Open Sans, 'Helvetica Neue', Arial, sans-serif;
        font-size: 13px;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.62;
        letter-spacing: normal;
        color: ${color.alabaster};
      }
    }

    & .share {
      margin-top: 36px;
      margin-bottom: 34px;
    }
  }
`;

const FeaturedWrapper = styled('div')`
  width: 100%;
  height: auto;
  user-select: none;
  position: relative;
  max-height: 600px;
  overflow: hidden;
  margin: 0 auto;
  border-radius: ${border.borderRadius4} ${border.borderRadius4} 0 0;
  .breadcrumbs {
    position: absolute;
    top: 20px;
    left: 30px;
    z-index: 1;
  }
  h1 {
    position: absolute;
    left: 100px;
    top: 70px;
    z-index: 1;
  }
  .gatsby-image-wrapper {
    height: 289px;
    border-radius: ${border.borderRadius4};
    img {
      object-fit: fill;
    }
  }
  @media (max-width: ${breakpoints.md}) {
    h1 {
      position: static;
      margin-top: 20px;
    }
    .breadcrumbs {
      position: static;
      margin: 10px 0;
    }
    .gatsby-image-wrapper {
      height: max-content;
    }
  }
`;

const NoFeaturedWrapper = styled('div')`
  .breadcrumbs,
  h1 {
    position: static;
    margin: 0 10% 30px;
  }

  @media (max-width: ${breakpoints.sm}) {
    .breadcrumbs,
    h1 {
      margin: 0 0 20px;
    }
  }
`;

const Sidebar = styled('div')`
  width: 268px;
  margin-left: auto;

  @media (max-width: ${breakpoints.md}) {
    width: auto;
    margin: 0 -16px;
  }
`;

const PostTemplate = ({ data, pageContext }) => {
  const { wordpressPost: post } = data;
  const category = post.categories.length > 0 ? post.categories[0] : {};
  const metaTitle = (post.yoast_meta && post.yoast_meta.yoast_wpseo_title) || post.title + +'- ' + process.env.OWNER;
  const metaDesc = (post.yoast_meta && post.yoast_meta.yoast_wpseo_metadesc) || '';
  const socialImage = post.featured_media?.localFile?.publicURL;

  return (
    <Layout postCategory={category}>
      <SEO
        title={metaTitle}
        description={metaDesc}
        pathname={post.path}
        key="seo"
        breadcrumbs={pageContext.breadcrumbs}
        socialImage={socialImage}
      />
      <Breadcrumbs breadcrumbs={pageContext.breadcrumbs} page_title={pageContext.page_title} />
      <WrapperDesktop>
        <ContentWrapper>
          <h1 dangerouslySetInnerHTML={{ __html: `${post.title}` }} />
          {getFile(post.featured_media) ? (
            <FeaturedWrapper key="featured-wrapper">
              <Img fluid={getFile(post.featured_media)} alt={post.featured_media.alt_text} />
            </FeaturedWrapper>
          ) : (
            <NoFeaturedWrapper key="no-featured-wrapper" />
          )}
          <div
            className="content"
            key="content"
            dangerouslySetInnerHTML={{
              __html: post_normalizer(post.content),
            }}
          />
          <div className="share">
            <span>{dictionary.share}: </span>
            <ButtonLink />
          </div>
        </ContentWrapper>
        <Sidebar>
          <TopTen />
        </Sidebar>
      </WrapperDesktop>
    </Layout>
  );
};

export default PostTemplate;

export const pageQuery = graphql`
  query ($id: String!) {
    wordpressPost(id: { eq: $id }) {
      wordpress_id
      title
      content
      path
      date(formatString: "MMMM DD, YYYY")
      yoast_meta {
        yoast_wpseo_title
        yoast_wpseo_metadesc
      }
      categories {
        name
        slug
        path
      }
      featured_media {
        alt_text
        localFile {
          publicURL
          childImageSharp {
            fluid(maxWidth: 1400) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    }
  }
`;
