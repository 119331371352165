const getPosition = (string, subString, index) => string.split(subString, index).join(subString).length;

export const post_normalizer = (post) => {
  let result = post;

  // magic crutch for empty tags at the start of the post
  if (result.substring(result.indexOf('>') + 1).startsWith('<')) {
    result = result.substring(getPosition(post, '>', 2) + 1);
  }

  // cut out image
  result = result.substring(result.indexOf('<h2>') && result.indexOf('<p>'));

  // even more magic crutch for double image
  while (result.substring(result.indexOf('>')).startsWith('><img')) {
    result = result.substring(result.indexOf('/>') + 2);
  }

  result = result.replace(/<div\b[^<]*(?:(?!<\/div>)<[^<]*)*<\/div>/gi, '');
  result = result.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');
  result = result.replace(/<style\b[^<]*(?:(?!<\/style>)<[^<]*)*<\/style>/gi, '');

  return result;
};
